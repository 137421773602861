.search-container {
  .header {
    .header-msg {
      margin-left: 16px;
      h1 {
        span {
          color: #4565f6;
          text-transform: capitalize;
          font-weight: 400;
        }
      }
    }
  }
  .tabs-Container {
    margin-top: 64px;
    .patient-tabs {
      background: #ffffff;
      border: 1px solid #eaecf4;
      border-radius: 15px;
      margin-bottom: 20px;
      max-width: 348px;
      .tabs {
        min-height: 50px;
        .atab {
          height: 42px;
          padding: 10px 12px 10px 12px;
          color: #a6a9c8;
        }
        .selected {
          background: #f5f5f9;
          border-radius: 15px;
          padding: 12px 17px 12px 14px;
          color: #4565f6;
        }
      }
    }
  }
  .doc {
    .title {
      font-size: 20px;
      line-height: 23px;
      text-transform: uppercase;
      color: #827f9b;
    }
    .list {
      margin-top: 27px;
      .docContainer {
        background: #ffffff;
        box-shadow: -11px 5px 40px -20px rgba(140, 151, 175, 0.4);
        border-radius: 20px;
        width: 263.75px;
        height: 202px;
        padding: 10px 10px 8px 12px;
        .titledoc {
          background: rgba(69, 101, 246, 0.08);
          border-radius: 10px;
          margin-bottom: 10px;
          p {
            padding: 12px 15px 10px 14px;
            font-weight: bold;
            font-size: 13px;
            line-height: 15px;
          }
        }
        .resultat {
          margin-top: 18px;
          margin-bottom: 8px;
          p {
            font-weight: bold;
            font-size: 18px;
            line-height: 21px;
          }
        }
        .specialite {
          font-size: 14px;
          line-height: 16px;
          color: #a6a9c8;
          background: rgba(166, 169, 200, 0.22);
          border-radius: 9px;
          p {
            padding: 10px 10px 9px 7px;
          }
        }
        // &:nth-child(even) {
        //   margin-left: 20.25px;
        //   margin-right: 20.25px;
        // }
      }
    }
    .nextRdvList {
      margin-bottom: 46px;
      .rdv {
        padding: 23px 20px 35px 11px;
        background: #4565f6;
        border-radius: 20px;
        margin-top: 17px;
        .header-rdv {
          p {
            font-size: 26px;
            line-height: 30px;
            color: #ffffff;
          }
          .connected {
            background-color: #34c7a4;
            border: 1px solid white;
            width: 9px;
            height: 9px;
          }
        }
        .boxSpecialite {
          margin-top: 6px;
        }
        .motif {
          margin-top: 15px;
          img {
            width: 22.88px;
            height: 22px;
            margin-right: 3px;
          }
        }
      }
      .rdvDone {
        padding: 23px 20px 35px 11px;
        border-radius: 20px;
        margin-top: 17px;
        &:nth-child(even) {
          margin-top: 20px;
        }
        .header-rdv {
          p {
            font-size: 26px;
            line-height: 30px;
          }
          .connected {
            background-color: #34c7a4;
            border: 1px solid white;
            width: 9px;
            height: 9px;
          }
        }
        .boxSpecialite {
          margin-top: 6px;
          background: rgba(166, 169, 200, 0.22) !important;
          p {
            color: #a6a9c8;
          }
        }
        .motif {
          margin-top: 15px;
          img {
            width: 15.89px;
            height: 20px;
            margin-right: 3px;
          }
          .nbDoc {
            padding: 12px 20px 12px 20px;

            border: 1px solid #4565f6;
            box-sizing: border-box;
            border-radius: 15px;
            color: #4565f6;
            p {
            }
          }
        }
      }
      .boxSpecialite {
        width: max-content;
        padding: 9px 19px 10px 18px;
        p {
          color: rgba(248, 248, 255, 0.7);
        }
      }
    }
    .medecin {
      margin-top: 89px;
      p {
        margin-bottom: 25px;
      }
      .menu {
        color: #a6a9c8;
      }
      button {
        padding: 16px 29.5px;
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
}
