.btn-nouveau-pat{
    width :270px;
    height:50px;
}

@media screen and (max-width: 900px)  {
    .btn-nouveau-pat{
        width: 200px;
        height:50px;
    }

}
@media screen and (max-width: 420px)  {
    .btn-nouveau-pat{
        width: 170px;
        height:50px;
    }

}

