.teleconsultation {
  .motif-border {
    background: rgba(166, 169, 200, 0.22);
    border-radius: 9px;
  }
}

.chat-teleconsultation {
  height: calc(100% - 60px);
  margin-bottom: -56px;
  // overflow-y: auto;
  overflow-x: hidden;
}
.chat-teleconsultation-doctor {
  height: calc(100% - 60px);
  // margin-bottom: -56px;
  // overflow-y: auto;
  overflow-x: hidden;
}

.tele-right-slide {
  height: 100%;
}

.modal-end-consultation {
  padding: 74.9px 61.9px 42.8px 62.9px;
  .menu {
    color: var(--color-primary-grey);
  }
}

.webrtc {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  width: 100%;

  video {
    height: max-content;
    width: 300px;
    border-radius: 20px;
    overflow: hidden;
    margin: 1px;
    border: 4px solid gray;
    cursor: pointer;
    z-index: 1;
  }

  .fullVideo {
    z-index: 1;
    width: auto;
    aspect-ratio: 16/9;
    object-fit: fill;
    max-height: 50vh;
    order: -1;
    flex: 1;
  }
  .-order-1 {
    order: -1;
  }
}
.control-call-container {
  position: fixed;
  bottom: 0;
  // left: 0;
  // right: 0;
  left: 45%;
  height: 70px;
  z-index: 999;
  background-color: transparent;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.shadow-black {
  box-shadow: 2px 2px 5px #bebebe;
}
