.tabs {
  height: 55px;
  border-radius: 15px;
  border: solid 1px #eaecf4;
  min-height: max-content !important;

  background-color: #fff;
  .tab {
    padding: 12px 18px;
    border-radius: 15px;
    // background-color: #f5f5f9;
  }
  .current {
    color: var(--color-primary-blue);
    font-weight: 500;
    background-color: #eaecf4;
    border-radius: 15px;
  }
}
