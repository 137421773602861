.agenda-config-form {
  padding-top: 20.9px;

  h1 {
    text-align: center;
  }

  > .button-group-wrapper {
    flex-grow: 0;
    margin: 1.4em 2.6em;
    padding: 0.25em;
    border-radius: 1em;
    border: solid 1px #eaecf4;
    background-color: #fff;

    button {
      width: 9.5em;

      height: 2.9em;
      flex-grow: 0;
      margin: 0;
      //margin: 0 17.2px 0 0;
      //padding: 12px 19.8px 12px 19.8px;
      border-radius: 15px;
      background-color: transparent;
      color: #a6a9c8;
      @media screen and (max-width: 992px) {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    button.active {
      background-color: #f5f5f9;
      color: #4565f6;
    }
  }
  > .button-group-wrapper-tabs {
    flex-grow: 0;
    margin: 1.4em 2.6em;
    padding: 0.25em;
    border-radius: 1em;
    border: solid 1px #eaecf4;
    background-color: #fff;

    button {
      height: 2.9em;
      flex-grow: 0;
      margin: 0;
      //margin: 0 17.2px 0 0;
      //padding: 12px 19.8px 12px 19.8px;
      border-radius: 15px;
      background-color: transparent;
      color: #a6a9c8;
      @media screen and (max-width: 992px) {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    button.active {
      background-color: #f5f5f9;
      color: #4565f6;
    }
  }

  .workingDays {
    margin-top: 10px;
    .day {
      max-height: 35px !important;
    }
  }
  .plageHoraire {
    margin-top: 32px;
    .sub-values {
      // margin-right: 50px;
      // margin-top: 8px;
      .value {
        background: #ffffff;
        border-radius: 10px;
        width: 47px;
        height: 37px;
        font-weight: bold;
        font-size: 13px;
        line-height: 15px;
        color: #4565f6;
        margin-left: 6px;
        margin-right: 6px;
      }
    }
  }
  select {
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid rgba(166, 169, 200, 0.28);
    box-sizing: border-box;
    border-radius: 9px;
    width: 109px;
    height: 45px;
  }
  .checkboxCont {
    margin-top: 62px;
    margin-bottom: 27px;
  }
  .rdvTimeContainer {
    margin-top: 82px;
  }
  .dashedButton {
    cursor: pointer;
    margin-top: 55px;
    border: 1px dashed var(--color-primary-blue);
    box-sizing: border-box;
    border-radius: 9px;
    // width: 415px;
    padding-left: 30px;
    padding-right: 30px;

    .menu {
      color: var(--color-primary-blue);
      padding: 15px 0;
    }
  }
}

// .button-group-wrapper {
//   flex-grow: 0;
//   margin: 0 0 4.9px;
//   padding: 0 221px 0.1px 0;

//   button {
//     padding: 0.3em;
//     flex-grow: 0;
//     // margin: 0 31px 0.1px 0;
//     font-size: 16px;
//     font-weight: 500;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: normal;
//     letter-spacing: normal;
//     text-align: center;
//     color: #a6a9c8;
//     background-color: transparent;
//   }

//   button.active {
//     color: #4565f6;
//     border-bottom: 1px solid #4565f6;
//   }
// }
