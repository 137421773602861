.addTraitementModal {
  background: #ffffff;
  box-shadow: -11px 0px 40px -20px rgba(140, 151, 175, 0.4);
  border-radius: 20px;
  min-width: 500px;
  @media screen and (max-width: 800px) {
    min-width: auto;
    width: 95%;
  }
}

.addAntecedentMedicalModal {
  background: #ffffff;
  box-shadow: -11px 0px 40px -20px rgba(140, 151, 175, 0.4);
  border-radius: 20px;
  min-width: 500px;
  @media screen and (max-width: 800px) {
    min-width: auto;
    width: 95%;
  }
  select {
    min-width: 100%;
    height: 60px;
    padding: 10px 15px 11.5px 20px;
    border-radius: 9px;
    border: solid 1px #a6a9c8;
    background-color: rgba(255, 255, 255, 0.5);
  }
}
.showObservationsModal {
  min-width: 500px;
  min-height: 250px;
  background: #ffffff;
  box-shadow: -11px 0px 40px -20px rgba(140, 151, 175, 0.4);
  border-radius: 20px;
}
