.card-patients-of-day-current {
  width: 365px;
  min-height: 242px;
  flex-grow: 0;
  margin: 20px 26px 49px 2px;
  padding: 23px 9px 10px 10px;
  border-radius: 20px;
  background-color: var(--color-primary-blue);
  color: white;

  * {
    color: white;
  }

  .sympt {
    width: 135px;
    height: 35px;
    flex-grow: 0;
    font-size: 12px;
    margin: 6px 51px 48px 9px;
    padding: 10px 10px 9px 12px;
    border-radius: 9px;
    color: rgba(248, 248, 255, 0.7);
    background-color: rgba(166, 169, 200, 0.22);
  }

  .sympt-white {
    color: #a6a9c8;
  }
}
.card-patients-of-day-default {
  width: 304px;
  min-height: 242px;
  flex-grow: 0;
  margin: 20px 26px 49px 2px;
  padding: 23px 9px 10px 10px;
  border-radius: 20px;
}

.hover-text-blue:hover {
  span {
    color: var(--color-primary-blue) !important;
  }
}
