.recommand {
    font-size: 14px;
    color: #a6a9c8;
}
.pointed{
    background-image: linear-gradient(to right, #a6a9c8 33%, rgba(255,255,255,0) 0%);
    background-position: bottom;
    background-size: 5px 2px;
    background-repeat: repeat-x;
    min-width: 100%;
    height: 2px;
}

.templateName {
    font-family: Ubuntu;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #000;
  }

  .templateNameSelected {
    font-family: Ubuntu;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #4565f6;
  }

.textPointed{
    border-bottom: 1px dashed  rgba(166, 169, 200, 0.25);
    min-height: 50px;
}