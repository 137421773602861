.dashboard-doctor {
  .mw-700 {
    max-width: 700px;
  }
  .body {
    display: flex;
    flex-direction: row;
  }
  .sideBar {
    flex: 0 0 1;
    width: 200px;
    padding: 10px;
    border-right: 1px solid #111;
    height: 100vh;
    background: #e1e1e1;
  }

  .sideBarItem {
    border: 1px solid #000;
    border-radius: 3px;
    height: 30px;
    padding: 10px;
    margin-top: 10px;
    background: #ccc;
  }

  .page {
    flex: 1 1 auto;
    padding: 0 20px;
    margin: 20px;
    border: 1px solid green;
  }

  .dropZone {
    flex: 0 0 auto;
    height: 40px;
    transition: 200ms all;
  }

  .dropZone:nth-of-type(2n) {
    display: none;
  }

  .dropZone.horizontalDrag {
    width: 40px;
    height: auto;
  }

  .dropZone:not(.horizontalDrag).isLast {
    flex: 1 1 auto;
  }

  .dropZone.active,
  .trashDropZone.active {
    background: #00a2ff;
    transition: 100ms all;
  }

  .component {
    height: 60px;
    padding: 10px;
    background: #aaa;
  }

  .column {
    border: 1px solid blue;
    flex: 1 1 100%;
    padding: 10px;
  }

  .columns {
    display: flex;
    padding: 20px 0;
  }

  .columnContainer {
    display: flex;
  }

  .base {
    padding: 0.5rem 1rem;
    background-color: white;
    cursor: move;
  }

  .row {
    border: 1px solid red;

    padding: 0;
  }

  .trashDropZone {
    position: relative;
    text-align: center;
    padding: 20px 0;
    margin: 0 auto;
    width: 100px;
    height: 100px;
    border: 1px solid purple;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pageContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 100px;
  }
}

.white-icon {
  filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(32deg) brightness(104%)
    contrast(102%);
}
