.card-patient {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  width: 549px;
  min-height: 123px;
  border-radius: 20px;
  @media screen and (max-width: 800px) {
    min-width: auto;
  }

  .patient-info {
    margin-bottom: 13px;
    .details {
      padding-top: 23px;
      .card-subtitle {
        margin-bottom: 11px;
        span {
          font-weight: 700;
        }
      }
    }
  }
  .patient-icons {
    margin-bottom: 13px;
    .icon-secondary {
      margin: 10px;
    }
  }

  .box-motif {
    flex-grow: 0;
    padding: 10px 12px 9px 14px;
    border-radius: 9px;
    background-color: rgba(166, 169, 200, 0.22);
    font-size: 14px;
    color: var(--color-primary-grey);
    // word-wrap: break-word;
  }
}
.selectedPatientDoctor {
  border: 3px solid #4565f6;
}
