.auth-check-Container {
  background-image: linear-gradient(to top, rgba(126, 69, 246, 0.6), #4196d0);

  .title {
    font-size: 36px;
    font-weight: bold;
    color: #fff;
    // margin-top: 85px;
    margin-bottom: 50px;
  }
  .logo-bloc {
    img {
      width: 76px;
      height: 76px;
    }
    .logo_text {
      font-size: 34.5px;
      font-weight: 900;
      color: #fff;
      margin-left: 19px;
      font-family: Roboto;
      letter-spacing: normal;
    }
  }
  .role {
    width: 240px;
    height: 260px;
    border-radius: 20px;
    box-shadow: 0 14px 60px 0 rgba(0, 0, 0, 0.15);
    background-color: #fff;
    padding-top: 50px;
    &:nth-child(1) {
      margin: 0 30px 0 0;
    }
    .icon-container {
      padding: 20px 20px 20px 20px;
      img {
        width: 35px;
        height: 35px;
      }
    }
  }
  .title-container {
    margin-bottom: 45px;
    margin-top: 24px;
    p {
      font-family: Ubuntu;
      font-size: 18px;
      color: #a6a9c8;
    }
    .hTitle {
      font-family: Ubuntu;
      font-size: 24px;
      font-weight: bold;
      color: #000;
    }
  }
  .create-Account {
    margin-top: 67px;
    .check-account {
      font-family: Ubuntu;
      font-size: 18px;
      // color: rgba(255, 255, 255, 0.7);
    }
    .create {
      margin: 10px 32px 0;
      font-family: Ubuntu;
      font-size: 18px;
      font-weight: 500;
      // color: #fff;
    }
  }

  @media screen and (max-width: 650px) {
    .role {
      width: 150px;
      height: 170px;
      border-radius: 20px;
      padding-top: 50px;
      &:nth-child(1) {
        margin: 0 30px 0 0;
      }
      .icon-container {
        padding: 0;
        img {
          width: 35px;
          height: 35px;
        }
      }
    }
  }
}
