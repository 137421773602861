// .header-container {
//   padding: 20px 20px 0px 20px;
//   .header-chat {
//     background: #f5f5f9;
//     border-radius: 20px;
//     padding: 12px 12px 13px 12px !important;
//     .folder-container {
//       button {
//         padding: 17px 14px 17px 14px;
//       }
//     }
//   }
//   .cros {
//     margin-left: 20px;
//   }
// }
.my-doctors {
  header {
    margin-bottom: 54px;
    @media screen and (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
  .card-container {
    // display: grid;
    // grid-template-columns: repeat(auto-fill, minmax(450px, auto));
    // grid-gap: 20px 10px;
    .card-specialist {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 15px 16px 22px;
      width: 500px;
      border-radius: 20px;
      background-color: #fff;
      min-height: 90px;
      @media screen and (max-width: 768px) {
        padding: 5px;
      }
      @media screen and (min-width: 768px) {
        .buttons {
          margin-left: 33px;
          button:nth-child(2) {
            margin-right: 11px;
            margin-left: 11px;
          }
        }
      }
    }
  }
}
.avatarMedecinChat {
  width: 60.9px;
  height: 60.9px;
  flex-grow: 0;
  border-radius: 20px;
  // background-color: lightblue;
}

// .chat-box {
//   .input-chat-box {
//     border-radius: 10px;
//     border: solid 1px rgba(166, 169, 200, 0.28);
//     background-color: rgba(255, 255, 255, 0.5);
//   }

//   .avatar-chat {
//     border-radius: 50%;
//     background-color: grey;
//     width: 35px;
//     height: 35px;
//     flex-grow: 0;
//   }
//   .message-chat {
//     flex-grow: 0;
//     font-size: 16px;
//     line-height: 1.25;
//   }

//   .me {
//   }
//   .you {
//     color: white;
//     background-color: var(--color-primary-blue);
//     border-radius: 20px;
//   }

//   .hr-center-text {
//     line-height: 1em;
//     position: relative;
//     outline: 0;
//     border: 0;
//     color: black;
//     text-align: center;
//     height: 1.5em;
//     opacity: 0.5;
//     &:before {
//       content: "";
//       // use the linear-gradient for the fading effect
//       // use a solid background color for a solid bar
//       background: linear-gradient(
//         to right,
//         transparent,
//         var(--color-primary-grey),
//         transparent
//       );
//       position: absolute;
//       left: 0;
//       top: 50%;
//       width: 100%;
//       height: 1px;
//     }
//     &:after {
//       content: attr(data-content);
//       position: relative;
//       display: inline-block;
//       color: black;

//       padding: 0 0.5em;
//       line-height: 1.5em;
//       // this is really the only tricky part, you need to specify the background color of the container element...
//       color: var(--color-primary-grey);
//       background-color: #fcfcfa;
//     }
//   }
// }

.shared-folder {
  select {
    -webkit-appearance: none;
    appearance: none;
  }
  .select-wrapper {
    position: relative;
  }

  .select-wrapper::after {
    content: "▼";
    font-size: 1rem;
    top: 25%;
    right: 10px;
    color: grey;
    position: absolute;
  }
  .box-selected {
    border: 1px solid var(--color-primary-blue);
    background-color: var(--color-primary-grey-background);
    color: var(--color-primary-blue);
  }
  .report-card {
    padding: 11px 12px 8px 10px;
    border-radius: 20px;
    background-color: #f2f2f7;
    width: 900px;
  }

  .updated-report {
    font-size: 13px;
    font-weight: bold;
    color: #a6a9c8;
  }
}

.patient-compte-rendu {
  // width: 641px;
  height: 100%;
  margin: 20px 20px 20px 0px;
  // box-shadow: -11px 0px 40px -20px rgba(140, 151, 175, 0.4);
  // background: #ffffff;
  // border-radius: 20px;
  .voir {
    padding: 12px 12px 12px 12px;
  }
  .buttons {
    margin-top: 81px;
    button:nth-child(2) {
      margin-left: 12px;
      margin-right: 12px;
    }
  }
  h1 {
    margin-top: 37px;
  }
  .data-container {
    padding-right: 70px;
    padding-left: 40px;
    .motifs-container {
      margin-top: 24px;
      .motifs {
        .boxSpecialite:nth-child(even) {
          margin-left: 12px;
        }
        margin-top: 10px;
      }
    }
    .questions-container {
      margin-top: 40px;
      .questions {
        padding-left: 20px;
        padding-top: 10px;
        .questionContainer {
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
          .point {
            margin-right: 10px;
            width: 5px;
            height: 5px;
            background-color: black;
          }
        }
      }
    }
    .conclusion {
      margin-top: 40px;
      p {
        padding-left: 20px;
        padding-top: 10px;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}

.svg-class {
  svg {
    filter: invert(29%) sepia(77%) saturate(2603%) hue-rotate(224deg)
      brightness(101%) contrast(93%);
  }
}

.patient-Transmettre {
  height: 100%;
  box-shadow: -11px 0px 40px -20px rgba(140, 151, 175, 0.4);
  background: #ffffff;
  border-radius: 20px;
}
