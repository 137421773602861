.item {
    cursor: move;
    list-style: none;
    margin: 5px;
    padding: 10px;
    border: 2px solid#e5e7eb;
    box-shadow: 5px 5px 3px #e5e7eb;
    border-radius: 10px;
    display: flex;
    position: relative;
    justify-content: flex-start 
}
.item > i {
    margin-right: 10px;
    padding-top:5px;
}
.item > div {
    margin-right: 10px
}