#instructions {
  color: white;
  position: absolute;
  left: 5%;
  bottom: 36px;
  font-family: monospace;
}

#rangenumber {
  width: 40px;
  padding: 4px 5px;
  margin: 0px 2px;
  border: 1px solid #bbb;
  border-radius: 3px;
}

#modelLabel {
  margin: 0px 10px;
}

.Prise3D {
  overflow: hidden;
  button {
    display: inline;
  }

  span {
    margin: 0px 2px;
    font-weight: bold;
  }
  input[type="range"].styled-slider {
    height: 5px;
    -webkit-appearance: none;
  }
}
