.chat-box {
  .input-chat-box {
    border-radius: 10px;
    border: solid 1px rgba(166, 169, 200, 0.28);
    background-color: rgba(255, 255, 255, 0.5);
  }
  .avatar-chat {
    border-radius: 50%;
    background-color: grey;
    width: 35px;
    height: 35px;
    flex-grow: 0;
  }
  .message-chat {
    flex-grow: 0;
    font-size: 16px;
    line-height: 1.25;
  }

  .you {
    color: white;
    background-color: var(--color-primary-blue);
    border-radius: 20px;
  }

  .hr-center-text {
    line-height: 1em;
    position: relative;
    outline: 0;
    border: 0;
    color: black;
    text-align: center;
    height: 1.5em;
    opacity: 0.5;
    &:before {
      content: "";
      background: linear-gradient(
        to right,
        transparent,
        var(--color-primary-grey),
        transparent
      );
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 1px;
    }
    &:after {
      content: attr(data-content);
      position: relative;
      display: inline-block;
      color: black;

      padding: 0 0.5em;
      line-height: 1.5em;
      // this is really the only tricky part, you need to specify the background color of the container element...
      color: var(--color-primary-grey);
      background-color: #fcfcfa;
    }
  }
}

.header-chat-patient {
  .infos {
    background: #f5f5f9;
    border-radius: 20px;
    .sub-infos {
      .details {
        font-size: 13px;
        margin-top: 20px;
        // @media screen and (max-width: 768px) {
        //   margin-top: 0 !important;

        //   }
        .name {
          // height: 23px;
          font-weight: bold;
          font-size: 20px;
          color: #000000;
        }
        .phonemail {
          &.div:first-child {
            margin-right: 30px;
          }
        }
        .mail {
          margin-left: 31px;
        }
        .birth {
          margin-bottom: 10px;
          .sub-birth {
            img {
              width: 17px;
              height: 17px;
            }
          }
          .dateBirth {
            p {
              font-size: 14px;
              font-weight: 400;
            }
          }
        }
      }
      .photo {
        width: 95px;
        height: 95px;
        margin: 20px 10px 20px 20px;
        border-radius: 20px;
        svg {
          width: 100px;
          height: 100px;
        }
      }
    }
    .patient-icons {
      margin: 10px 12.5px 10px 12.5px;
    }
  }
}
.header-container {
  padding: 20px 20px 0px 20px;
  .header-chat {
    background: #f5f5f9;
    border-radius: 20px;
    padding: 12px 12px 13px 12px !important;
    .folder-container {
      button {
        padding: 17px 14px 17px 14px;
      }
    }
  }
  .cros {
    margin-left: 20px;
  }
}

.birth-chat-header {
  margin-top: 10px;
  .sub-birth {
    img {
      width: 17px;
      height: 17px;
    }
  }
  .dateBirth {
    p {
      font-size: 16px;
      font-weight: 400;
    }
  }
}
