.btn-payment {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.card-teleconsultation-patient {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  border-radius: 20px;

  .patient-info {
    margin-bottom: 13px;
    .details {
      padding-top: 23px;
      .card-subtitle {
        margin-bottom: 11px;
        span {
          font-weight: 700;
        }
      }
    }
  }
  .patient-icons {
    display: flex;
    width: fit-content;
    .icon-secondary {
      margin: 10px;
      @media screen and (max-width: 800px) {
        margin: 2px;
      }
    }
  }
}
