.cursusAdd {
  background: #ffffff;
  box-shadow: -11px 0px 40px -20px rgba(140, 151, 175, 0.4);
  border-radius: 20px;
  @media screen and (max-width: 900px) {
    top: 1rem;
  }
  .form-cursus {
    div:nth-child(even) {
      margin-left: 10px;
      margin-right: 10px;
      @media screen and (max-width: 1280px) {
        margin-right: 0;
        margin-left: 0;
      }
    }
    .buttons {
      button {
        min-width: 40px;
        &:nth-child(1) {
          margin-right: 10px;
        }
      }
    }
  }
  .check {
    font-size: 16px;
    line-height: 16px;
    color: #a6a9c8;
    &:first-child {
      margin-left: 6px;
    }
  }
}

.modalSites {
  input {
    min-width: 100%;
    height: 45px;
    padding: 10px 10px 11.5px 37px;
    border-radius: 9px;
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid rgba(166, 169, 200, 0.28);
    &:nth-child(even) {
      margin-bottom: 10px;
    }
  }
}

.container {
  .dropzone {
    margin: 10px 20px 20px 20px;
    border-radius: 20px;
    border: 2px aquamarine dashed;
    height: 90px;
  }
  img {
    display: block;
    width: 150px;
    max-height: 150px;
  }
}

.mdal-add-presentation {
  background: #ffffff;
  box-shadow: -11px 0px 40px -20px rgba(140, 151, 175, 0.4);
  border-radius: 20px;
}

.h-add-tarif-modal {
  @media screen and (max-width: 768px) {
    height: 90%;
    width: 97%;
  }
}

.descriptionWidth,
.expertiseWidth,
.cursusWidth {
  // width: 50vw;
  @media screen and (max-width: 768px) {
    width: 80vw;
  }
}

.blockedModalWidth {
  width: 70vw;
}
