.annuaire-patient {
  .card-doctor {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 15px 16px 22px;
    border-radius: 20px;
    background-color: #fff;
    min-height: 90px;
    min-width: 549px;
    max-width: 549px;
    margin: 20px;

    width: 549px;
    @media screen and (max-width: 500px) {
      // border: 2px solid red;
      min-width: 97% !important;
      max-width: 97% !important;
      width: 97% !important;
      padding: 7px;
      margin-left: initial;
    }
  }

  // .drawer-width {
  //   width: 650px;
  // }
}
