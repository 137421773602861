.new-event-form {
  padding-top: 60.9px;

  h1 {
    text-align: center;
  }

  > .button-group-wrapper {
    flex-grow: 0;
    margin: 1.4em 2.6em;
    padding: 0.25em;
    border-radius: 1em;
    border: solid 1px #eaecf4;
    background-color: #fff;

    button {
      width: 9.5em;
      height: 2.9em;
      flex-grow: 0;
      margin: 0 17.2px 0 0;
      padding: 12px 19.8px 12px 19.8px;
      border-radius: 15px;
      background-color: transparent;
      color: #a6a9c8;
    }

    button.active {
      background-color: #f5f5f9;
      color: #4565f6;
    }
  }
  .tabs-Container {
    //margin-top: 24px;
    .patient-tabs {
      background: #ffffff;
      //border: 1px solid #eaecf4;
      border-radius: 15px;
      margin-bottom: 20px;
      max-width: 348px;
      .tabs {
        min-height: 50px;
        .atab {
          height: 42px;
          padding: 10px 12px 10px 12px;
          color: #a6a9c8;
        }
        .selected {
          background: #f5f5f9;
          border-radius: 15px;
          padding: 12px 17px 12px 14px;
          color: #4565f6;
        }
      }
    }
  }
  select,
  input:not([type="number"]) {
    width: 417px;
    height: 45px;
    margin-bottom: 12px;
    @media screen and (max-width: 576px) {
      width: 320px !important;
    }
  }
  .miniAgenda {
    min-width: 515px;
    @media screen and (max-width: 576px) {
      min-width: unset !important;
    }
  }
  .switchCointainer {
    margin-top: 30px;
    margin-bottom: 25px;
  }
}

.new-event-appointment-form {
  > .button-group-wrapper {
    width: 100%;
    height: 18.1px;
    flex-grow: 0;
    margin: 0 0 4.9px;
    padding: 1px;

    display: flex;
    justify-content: center;
    align-items: center;
    button {
      width: 8em;
      padding: 0.3em;
      flex-grow: 0;
      margin: 0 31px 0.1px 0;
      font-family: Ubuntu;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #a6a9c8;
      background-color: transparent;
    }

    button.active {
      color: #4565f6;
      border-bottom: 1px solid #4565f6;
    }
  }
  input[type="text"] {
    width: 417px;
    height: 45px;
    padding: 10px 10px 11.5px 37px;
    border-radius: 9px;
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid rgba(166, 169, 200, 0.28);
  }
}
.sub-values-act {
  .react-time-picker__inputGroup {
    display: flex !important;
    align-items: center !important;
  }
}
