.addTraitementModal {
  background: #ffffff;
  box-shadow: -11px 0px 40px -20px rgba(140, 151, 175, 0.4);
  border-radius: 20px;
  min-width: 500px;
}

.addAntecedentMedicalModal {
  background: #ffffff;
  box-shadow: -11px 0px 40px -20px rgba(140, 151, 175, 0.4);
  border-radius: 20px;
  min-width: 500px;
  @media screen and (max-width: 800px) {
    min-width: auto;
    width: 95%;
  }
  select {
    min-width: 100%;
    height: 60px;
    padding: 10px 15px 11.5px 20px;
    border-radius: 9px;
    border: solid 1px #a6a9c8;
    background-color: rgba(255, 255, 255, 0.5);
  }
  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      input:not([type="checkbox"]):not([type="radio"]) {
        width: 100%;
        height: 56px;
        padding: 0.75rem;
        border-radius: 9px;
        background: rgba(255, 255, 255, 0.5);
        border: 1px solid rgba(166, 169, 200, 0.28);
        &::placeholder {
          font-size: 14px;
          line-height: 18px;
          color: #a6a9c8;
          height: 18px;
        }
      }
      input:not([type="radio"]) {
        width: 100%;
        height: 56px;
        padding: 0.75rem;
        border-radius: 9px;
        background: rgba(255, 255, 255, 0.5);
        border: 1px solid rgba(166, 169, 200, 0.28);
        &::placeholder {
          font-size: 14px;
          line-height: 18px;
          color: #a6a9c8;
          height: 18px;
        }
      }
    }
  }
}
.showObservationsModal {
  min-width: 500px;
  min-height: 250px;
  background: #ffffff;
  box-shadow: -11px 0px 40px -20px rgba(140, 151, 175, 0.4);
  border-radius: 20px;
}
