.facture {
  .headerContainer {
    //   // width: 542px;
    height: 67px;
    border-bottom: 1px solid rgba(166, 169, 200, 0.3);
    .svgClass {
      width: 26px;
      height: 26px;
      margin-left: 20px;
    }
    .header {
      width: 167px;
      margin-left: 6px;
      font-size: 20px;
      line-height: 23px;
      text-transform: uppercase;
      color: #827f9b;
      letter-spacing: -0.5px;
    }
  }
}
