.widget {
  max-width: 281.4px;
  .widget-title {
    font-size: 16px !important;
    font-weight: bold !important;
  }
  .widget-dsecription {
    max-width: 228px !important;
  }
  .round-icon {
    height: 46.5px;
    width: 46.5px;
    border-radius: 50%;
    background-color: #ebecf3;
  }
}

.icon-blue {
  filter: invert(34%) sepia(65%) saturate(5009%) hue-rotate(225deg)
    brightness(105%) contrast(93%);
}
