.preview-Page {
  h3 {
    font-size: 16px !important;
  }
  button {
    min-width: 41px;
  }
  .svgBlue {
    filter: invert(32%) sepia(59%) saturate(5833%) hue-rotate(227deg)
      brightness(110%) contrast(93%);
  }
}

.patient-prescription-modal {
  // min-width: 460px;
  // max-width: 641px;
  margin: 20px 20px 20px 0px;
  box-shadow: -11px 0px 40px -20px rgba(140, 151, 175, 0.4);
  background: #ffffff;
  border-radius: 20px;
  &::-webkit-scrollbar {
    display: none;
  }
  .header {
    margin-top: 26px;
  }
  .container {
    padding: 20px;
    .infoBloc {
      margin-top: 47px;
      h3 {
        line-height: 23px;
      }
      p {
        font-family: Roboto;
        font-size: 14px;
        line-height: 18px;
        margin-left: 20px;
      }
    }
  }
  button:not([type="button"]) {
    margin-top: 63px;
  }
}

.preview-Page {
  h3 {
    font-size: 16px !important;
  }
  button {
    min-width: 41px;
  }
  .svgBlue {
    filter: invert(32%) sepia(59%) saturate(5833%) hue-rotate(227deg)
      brightness(110%) contrast(93%);
  }
}

.patient-transmettre-modal {
  // min-width: 522px;
  min-height: 629.86px;
  max-height: 629.86px;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: -11px 0px 40px -20px rgba(140, 151, 175, 0.4);
  border-radius: 20px;
  .container {
    // width: 480.01px;
    height: 90px;
    background: #f5f5f9;
    border-radius: 20px;
    padding: 11px 12px;
    img {
      max-width: 73px;
      height: auto;
      border-radius: 20px;
    }
    button {
      min-width: 155px;
    }
  }
  .search {
    margin-top: 32px;
  }
}
