.dashboard-Patient {
  .dashboard-title {
    h1 {
      line-height: 41px;
    }
    span {
      font-size: 14px;
      line-height: 16px;
      color: #a6a9c8;
    }
  }
  .gridContainer {
    margin-top: 40px;
    .col-span-2 {
      max-width: 697px;
    }
    h3 {
      margin-bottom: 20px;
    }
    .medecinTraitant {
      margin-top: 50px;
    }
    .my-doctor {
      background: #ffffff;
      box-shadow: -11px 5px 40px -20px rgba(140, 151, 175, 0.4);
      border-radius: 20px;
      min-height: 300px;
      hr {
        margin-right: 19px;
        margin-left: 19px;
      }
      .header {
        padding: 8px 8px 14px 9px;
        .doctor-image {
          width: 72px;
          height: 72px;
        }
        .name {
          padding-left: 14px;
          padding-right: 43px;
          p {
            font-size: 20px;
            line-height: 23px;
            line-height: 23px;
          }
          span {
            font-size: 13px;
            line-height: 15px;
            color: #a6a9c8;
            line-height: 15px;
          }
        }
      }
    }
    .rdv-container {
      .hasBackgounrd {
        background-color: #4565f6;
      }
      gap: 39px;

      // @media screen and (max-width: 1200px) {
      //   gap: 0px;
      // }
      .rdv-bloc {
        width: 328px;
        min-height: 242px;
        border-radius: 20px;
        .header {
          padding: 23px 10px 6px 10px;

          .colorSvg {
            filter: invert(100%) sepia(1%) saturate(0%) hue-rotate(153deg)
              brightness(104%) contrast(101%);
          }
          h2 {
            margin-right: 13px;
          }
          span {
            background-color: #34c7a4;
            border: 1px solid white;
            width: 9px;
            height: 9px;
          }
        }
        .boxSpecialite {
          margin-left: 12px;
          p {
            color: rgba(248, 248, 255, 0.7);
            padding: 9px 12px 10px 13px;
          }
        }

        .box-motif {
          margin-left: 12px;
          margin-right: 12px;
          min-width: 158px;
          min-height: 35px;
          background: rgba(166, 169, 200, 0.35) !important;
          border-radius: 9px;
          p {
            font-size: 14px;
            line-height: 16px;
            color: #a6a9c8;
          }
          @media screen and (max-width: 500px) {
            width: 90%;
          }
          p {
            color: rgba(248, 248, 255, 0.7);
            padding: 9px 12px 10px 13px;
          }
        }

        button {
          margin: 7px 12px 10px 12px;
          width: -webkit-fill-available;
        }
      }
    }
    .recent-docs {
      .recent-bloc {
        &:nth-child(n) {
          margin-bottom: 20px;
        }
        background: #ffffff;
        box-shadow: -11px 5px 40px -20px rgba(140, 151, 175, 0.4);
        border-radius: 20px;
        padding: 7px 14px 17px 13px;
        .titleHelper {
          font-size: 13px;
          line-height: 15px;
          color: #a6a9c8;
          margin-top: 18px;
          margin-bottom: 7px;
        }
        .specialite {
          background: rgba(166, 169, 200, 0.22);
          border-radius: 9px;
          p {
            padding: 10px 10px 9px 10px;
            font-size: 14px;
            line-height: 16px;
            color: #a6a9c8;
          }
        }
      }
    }
  }
  .doctor-modal-patient-info {
    min-width: 641px;
    max-width: 641px;
    height: 100%;
    margin: 20px 20px 20px 0px;
    box-shadow: -11px 0px 40px -20px rgba(140, 151, 175, 0.4);
    background: #ffffff;
    border-radius: 20px;
    padding: 20px;
    .header {
      .doctor-image {
        width: 72px;
        height: 72px;
      }
      .name {
        margin-left: 15px;
        p {
          font-weight: bold;
          font-size: 30px;
          line-height: 34px;
        }
        span {
          font-size: 13px;
          line-height: 15px;
          color: #a6a9c8;
        }
      }
    }
    .patient-tabs {
      background: #ffffff;
      border: 1px solid #eaecf4;
      border-radius: 15px;
      margin-bottom: 26px;
      margin-top: 16px;
      height: 50px;
      .tabs {
        min-height: 50px;
        .atab {
          padding: 10px 12px 10px 12px;
          &:nth-child(even) {
            margin-right: 47px;
            margin-left: 47px;
          }
        }
        .selected {
          background: #f5f5f9;
          border-radius: 15px;
          padding: 12px 45px 12px 45px;
          color: #4565f6;
          margin: 4px 47px 4px 4px;
        }
      }
    }
    .dataContainer {
      min-width: 601px;
      background: #f5f5f9;
      box-shadow: -11px 5px 40px -20px rgba(140, 151, 175, 0.4);
      border-radius: 20px 20px 0px 0px;
      padding: 20px 20px 0px 20px;
      .doctor-info {
        h3 {
          margin-bottom: 20px;
        }
        .location {
          .address {
            p {
              font-size: 19px;
              font-weight: 700;
              line-height: 18px;
            }
            span {
              font-size: 14px;
              line-height: 16px;
            }
            .svgClass {
              padding-right: 12.5px;
            }
          }
          .num {
            &:first-child {
              margin-bottom: 6px;
            }
            .phone {
              margin-bottom: 5.49px;
              p {
                font-size: 14px;
                line-height: 16px;
              }
              img {
                height: 15.5px;
                margin-right: 11.49px;
              }
            }
            .place {
              p {
                font-size: 14px;
                line-height: 16px;
              }
              img {
                height: 16.5px !important;
                margin-right: 11.49px;
              }
            }
          }
        }
      }

      .horaireMap {
        .horaire {
          margin-top: 17px;
          .agenda {
            margin-bottom: 12px;

            .time {
              font-size: 14px;
              line-height: 16px;
              min-width: 82px;
            }
            .name {
              font-weight: bold;
              font-size: 16px;
              line-height: 18px;
              min-width: 31px;
            }
          }
          .imgContainer {
            margin-right: 11px;
          }
        }
      }
      .expertises {
        margin-top: 40px;
        h3 {
          margin-bottom: 12px;
        }
        .list {
          .expertise {
            background: rgba(166, 169, 200, 0.15);
            border-radius: 9px;
            padding: 10px 13px 9px 14px;
            font-size: 14px;
            line-height: 16px;
            color: #a6a9c8;
            &:nth-child(n) {
              margin-right: 20px;
            }
          }
        }
      }
      .tarifsContainer {
        margin-top: 40px;
        h3 {
          margin-bottom: 12px;
        }
        .tarifs {
          .tarif {
            p {
              line-height: 18px;
            }
            div {
              background: #ffffff;
              border-radius: 10px;
              padding: 11px 16px 11px 16px;
              margin-right: 6px;
              margin-left: 4px;
              font-weight: bold;
              font-size: 13px;
              line-height: 15px;
              color: #4565f6;
            }
            &:nth-child(even) {
              margin-left: 35px;
              margin-right: 35px;
            }
          }
        }
      }
      .horizontalLigne {
        background: #f5f5f9;
        box-shadow: -11px 5px 40px -20px rgba(140, 151, 175, 0.4);
        border-radius: 20px 20px 0px 0px;
        margin-top: 22px;
        margin-bottom: 10px;
      }
      .reglement {
        p {
          font-size: 16px;
          line-height: 18px;
        }
      }
    }
    .rdv {
      min-height: 120px;
      .next-rdv {
        margin-right: 20px;
      }
    }
  }
  .timeslot {
    cursor: pointer;
  }
}

.modal-change-doctors {
  width: 600px;
  height: 300px;
  @media screen and (max-width: 650px) {
    width: calc(100vw - 50px);
    height: 360px;
  }
}
