.prescription {
  @media screen and (min-width: 523px) {
    min-width: 522px;
  }
  padding: 24px 7.4px 54px 20px;
  box-shadow: -11px 0 40px -20px rgba(140, 151, 175, 0.4);
  .icon {
    background-color: rgba(235, 236, 243, 0.5);
    border-radius: 10px;
  }
  .subtitle {
    flex-grow: 0;
    font-size: 18px;
    color: #827f9b;
  }
  .date-time-box {
    border-radius: 10px;
    background-color: #f2f2f7;
    padding: 11px 13px;
    margin: 0 10px 0 8px;
    font-size: 13px;
    font-weight: bold;
    color: #4565f6;
  }
}
