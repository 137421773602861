.modal-end-consultation {
  padding: 74.9px 61.9px 42.8px 62.9px;
  .menu {
    color: var(--color-primary-grey);
  }
}

.rdw-editor-wrapper {
  display: flex !important;
  flex-direction: column-reverse !important;
  height: 100% !important;
}

.rdw-editor-main {
  height: 95%;
  overflow: auto;
}

.rdw-editor-toolbar {
  justify-content: center !important;
}
