.konvajs-content {
  background: linear-gradient(#000, #000) no-repeat center/1px 100%;
}

// .MenuContainer{
//   margin-right: 20px;
//   background-color: transparent;
// }

.box-tools {
  padding: 20px;
  background-color: var(--color-primary-grey-background);
  border-radius: 15px;
  height: 100%;
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  .ListItem {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    li {
      min-width: 200px;
      margin: 10px 5px;
      border-radius: 10px;
      padding: 10px 15px;
      color: var(--color-primary);
      box-shadow: 5px 7px #e5e7eb;
      border: 1px solid #e5e7eb;
      font-size: 14px;

      cursor: pointer;
      // text-align: center;
    }
  }
}

.App {
  display: flex;
}

.TextListMenu {
  font-weight: bold;
}

.back canvas {
  background-size: 10px 10px !important;
  background-image: linear-gradient(to right, #c5c5c5 1px, transparent 1px),
    linear-gradient(to bottom, #c5c5c5 1px, transparent 1px) !important;
}

.back {
  //background-color: white;
}

.btnPDF {
  border-radius: 10px;
  background-color: #4565f4;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  cursor: pointer;
  width: 150px;
  height: 54px;
}
.btnSpace {
  margin-left: 15px;
  margin-right: 15px;
}

.repeatText {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalName {
  width: 500px;
  padding: 20px;
}

.fileInput {
  max-width: 200px;
  &:hover {
    cursor: pointer;
  }
}

.fontStylingText {
  width: 220px;
  margin: 10px 5px;
  border-radius: 10px;
  padding: 10px 15px;
  color: var(--color-primary);
  box-shadow: 5px 7px #e5e7eb;
  border: 1px solid #e5e7eb;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
}
