.my-account-patient {
  .card-patient-details .avatar {
    width: 102px;
    height: 105px;
    img {
      width: 102px;
      height: 102px;
      object-fit: fill;
    }
    // background-color: #ccd2ff;

    svg {
      width: 100px;
      height: 100px;
    }
  }
  .resultIMC {
    * {
      padding: 0;
      margin: 0;
    }
    padding: 0 10px !important;
  }
}

.custom-h2 {
  color: var(--color-primary-grey);
  font-weight: normal;
}
.document-image {
  width: 120px !important;
  height: 120px !important;
}
.report-box {
  border-radius: 20px;
  box-shadow: -11px 5px 40px -20px rgba(140, 151, 175, 0.4);
  background-color: #f5f5f9;
  .last-update {
    font-size: 13px;
    color: #a6a9c8;
  }
}
.edit-email,
.edit-password {
  width: 570px;
  @media screen and (max-width: 768px) {
    width: 350px;
  }
}
.show-more-hour {
  font-size: 16px;
  font-weight: 500;
  color: #4565f6;
}

.agenda-icon {
  filter: invert(72%) sepia(23%) saturate(274%) hue-rotate(197deg)
    brightness(90%) contrast(91%);
}

.agenda-icon-blue {
  filter: invert(37%) sepia(67%) saturate(4800%) hue-rotate(222deg)
    brightness(100%) contrast(94%);
}

.close-modal {
  width: 50px;
  height: 50px;
  border-radius: 15px;
  background-color: rgba(235, 236, 243, 0.5);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.close-modal-float {
  width: 50px;
  height: 50px;
  border-radius: 15px;
  background-color: rgba(235, 236, 243, 0.5);
  cursor: pointer;
}

.box-imc {
  display: block;
  flex-grow: 0;
  padding: 10px 12px 9px 14px;
  border-radius: 9px;
  background-color: rgba(166, 169, 200, 0.22);
  font-size: 14px;
  color: var(--color-primary-grey);
}
