.login {
  background-image: linear-gradient(to top, rgba(126, 69, 246, 0.6), #4196d0);
  background-size: cover;
  background-position: center;
  .auth-login-Container {
    .title {
      color: white;
      margin-bottom: 33px;
    }
    .formLogin {
      flex-grow: 0;
      padding: 30px 19px 30px 20px;
      border-radius: 20px;
      box-shadow: 0 14px 60px 0 rgba(0, 0, 0, 0.15);
      background-color: #fff;
      input {
        width: 361px;
        height: 60px;
        //padding: 10px 153px 11.5px 20px;
        border-radius: 9px;
        border: solid 1px #a6a9c8;
        background-color: rgba(255, 255, 255, 0.5);
      }
      .title-container {
        margin-bottom: 20px;
        margin-top: 20px;
        p {
          font-size: 18px;
          color: #a6a9c8;
        }
        h1 {
          font-size: 24px;
          font-weight: bold;
          color: #000;
        }
      }
      .btn {
        width: 234px;
        height: 54px;
        margin-top: 32px;
      }
      .mdp {
        font-size: 14px;
        text-align: right;
        color: #4565f6;
        margin-top: 8px;
      }
      .custom-Input {
        font-size: 16px;
        color: #a6a9c8;
      }
      .check-account {
        font-size: 18px;
        text-align: center;
        // color: rgba(255, 255, 255, 0.7);
      }
      input[type="checkbox"] {
        height: 18px;
        width: 18px;
      }
    }

    .logo-bloc {
      img {
        width: 76px;
        height: 76px;
      }
      .logo_text {
        font-size: 34.5px;
        font-weight: 900;
        color: #fff;
        margin-left: 19px;
        font-family: Roboto;
        letter-spacing: normal;
      }
    }
    .create-Account {
      margin-top: 46px;
      .check-account {
        font-family: Ubuntu;
        font-size: 18px;
        // color: rgba(255, 255, 255, 0.7);
      }
      .create {
        margin: 10px 32px 0;
        font-family: Ubuntu;
        font-size: 20px;
        font-weight: 500;
        // color: #fff;
      }
    }
  }

  @media screen and (max-width: 420px) {
    .formLogin {
      padding: 30px 10px !important;
      margin: auto 20px;

      input[type="text"] {
        width: 320px !important;
      }
      input[type="password"] {
        width: 320px !important;
      }
    }
  }
}
