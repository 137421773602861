#agenda {
  .fc-scrollgrid-section-body {
    max-height: 100%;
    overflow-y: scroll;
  }
  .fc-button:focus,
  .fc-button:focus {
    box-shadow: unset;
  }

  .fc-header-toolbar {
    @media screen and (max-width: 992px) {
      display: flex !important;
      flex-direction: column !important;
    }
    .fc-toolbar-chunk:first-of-type > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      .fc-prev-button,
      .fc-next-button {
        border: unset !important;
        background-color: unset !important;
      }

      .fc-toolbar-title {
        text-transform: capitalize;
        white-space: nowrap;
        @media screen and (max-width: 992px) {
          font-size: 28px !important;
        }
      }

      .fc-today-button {
        margin-right: 30px;
      }

      .fc-prev-button:focus,
      .fc-next-button:focus {
        box-shadow: unset;
      }
    }

    .fc-timeGridDay-button,
    .fc-timeGridWeek-button,
    .fc-dayGridMonth-button {
      border: unset !important;
      background-color: unset !important;
      color: var(--color-primary-grey) !important;
      margin: 5px;
      height: 40px !important;
    }

    .fc-timeGridDay-button.fc-button-active,
    .fc-timeGridWeek-button.fc-button-active,
    .fc-dayGridMonth-button.fc-button-active {
      color: var(--color-primary-blue) !important;
      background-color: var(--color-primary-grey-background) !important;
    }

    .fc-views-button-group-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 435px;
      height: 50px;
      border-radius: 15px !important;
      border: solid 1px #eaecf4 !important;
      background-color: #fff !important;
      z-index: -1;
      @media screen and (max-width: 992px) {
        background-color: transparent !important;
        border: none !important;
        right: 0 !important;
        display: flex !important;
        justify-content: center !important;
        width: 300px !important;
      }
    }

    .fc-addEvent-button {
      background-color: var(--color-primary-blue) !important;
      color: #fff !important;
      width: 12.9em !important;
      margin-left: 19.6px;
    }

    .fc-configureAgenda-button {
      color: #000 !important;
      border: unset !important;
      background-color: unset !important;
      width: 3em !important;
      font-size: 1.75em;
      padding-top: 0;
      padding-bottom: 0;
    }

    .fc-configureAgenda-button:focus {
      box-shadow: unset;
    }
  }

  .fc-timegrid-now-indicator-line {
    display: none;
  }

  .fc-timegrid-now-indicator-arrow {
    border-style: unset;
    border-color: unset;
    margin-top: unset;
  }

  .fc-timegrid-now-indicator-container {
    overflow: unset;
  }

  .fc-timegrid-custom-now-indicator-line {
    border-style: solid;
    border-color: var(--color-primary-blue);
    border-width: 1.5px 0 0;
  }

  .fc-timegrid-custom-now-indicator-tag-wrapper {
    position: relative;
    width: 3em;
  }

  .fc-timegrid-custom-now-indicator-tag-text {
    position: absolute;
    background-color: var(--color-primary-blue);
    color: #fff;
    border-radius: 15px !important;
    left: 0;
    right: 0;
    top: -0.6em;
    height: 1.2em;
    font-size: 13px;
    line-height: 1.2em;
    text-align: center;
  }

  .fc-day-today {
    background-color: unset;
  }

  .fc-scroller colgroup col,
  .fc-timegrid-slots colgroup col,
  .fc-timegrid-cols colgroup col {
    width: 136px !important;
  }

  .fc-timegrid-slot-label-frame {
    text-align: left;
    color: var(--color-primary-grey);
  }

  .fc-custom-dayheader-weekday {
    color: var(--color-primary-grey);
  }

  .fc-custom-dayheader-dayofmonth {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 16px;
  }

  .fc-custom-dayheader-dayofmonth.fc-custom-dayheader-dayofmonth-istoday {
    border-bottom: solid 1px var(--color-primary-blue);
  }

  table,
  th,
  td {
    border: unset;
  }

  .fc-timegrid-cols tr > td:not(:last-child) {
    border-right: solid 1px var(--color-primary-grey);
  }

  .fc-timegrid-slots tbody > tr:first-child > .fc-timegrid-slot-lane {
    border-top: dotted 1px var(--color-primary-grey);
  }

  .fc-timegrid-slots tbody > tr:nth-child(3n) > .fc-timegrid-slot-lane {
    border-bottom: dotted 1px var(--color-primary-grey);
  }

  .fc-event {
    background-color: unset;
    border: unset;
    box-shadow: unset;

    .fc-custom-event-content {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      // background-color: #fff;
      color: #000;
      justify-content: flex-start;
      padding: 4px 9px;
      border-radius: 0 9px 9px 0;
      border-left: solid 3.2px var(--color-primary-grey);
      overflow: hidden;
      cursor: pointer;

      .fc-custom-event-time {
        font-size: 10px;
        color: var(--color-primary-grey);
      }

      .fc-custom-event-patient {
        font-size: 13px;
        text-align: left;
        font-weight: bold;

        .fc-custom-event-patient-lastname {
          padding-left: 0.3em;
          text-transform: uppercase;
        }
      }

      .fc-custom-event-purpose {
        padding: 2.5px 0 2.5px 5px;
        margin-top: 2.5px;
        font-size: 11px;
      }

      .fc-custom-event-open-text {
        font-size: 13px;
        text-align: left;
        font-weight: bold;
        color: var(--color-primary-grey);
      }
    }

    .fc-custom-event-open {
      background-color: #fff8;
    }

    .fc-custom-event-open:hover {
      background-color: #fff;
    }
  }
}
.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  max-height: 146px !important;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}
