.historiques {
  .card-histoique {
    margin: 20px 20px 20px 0px;
    box-shadow: -11px 0px 40px -20px rgba(140, 151, 175, 0.4);
    background: #ffffff;
    border-radius: 20px;
  }
}

.treatment-option:hover .end-treatment {
  display: block;
}

.birth-header {
  margin-top: 10px;
  .sub-birth {
    img {
      width: 17px;
      height: 17px;
    }
  }
  .dateBirth {
    p {
      font-size: 16px;
      font-weight: 400;
    }
  }
}

.warn,
.warn::before,
.warn::after {
  position: relative;
  padding: 0;
  margin: 0;
}

.warn {
  font-size: 20px;
  color: transparent;
}
.warn.warning {
  display: inline-block;

  top: 0.225em;

  width: 1.15em;
  height: 1.15em;

  overflow: hidden;
  border: none;
  background-color: transparent;
  border-radius: 0.625em;
}
.warn.warning::before {
  content: "";
  display: block;
  top: -0.08em;
  left: 0em;
  position: absolute;
  border: transparent 0.6em solid;
  border-bottom-color: #fd3;
  border-bottom-width: 1em;
  border-top-width: 0;
  box-shadow: #999 0 1px 1px;
}

.warn.warning::after {
  display: block;
  position: absolute;
  top: 0.15em;
  left: 0;
  width: 100%;
  padding: 0 1px;
  text-align: center;
  font-family: "Garamond";
  content: "!";
  font-size: 0.65em;
  font-weight: bold;
  color: #333;
}

.INS-provisoire {
  background-color: #b51621;
}
.INS-recovered {
  background-color: #0070c0;
}
.INS-validated {
  background-color: #ffeb61;
}
.INS-qualivied {
  background-color: #92d050;
}

.shim-green {
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 255, 0, 0.7);
}
.shim-green::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(233, 233, 233, 1) 0,
    rgba(233, 233, 233, 0.9) 50%,
    rgba(233, 233, 233, 0.8) 100%
  );
  animation: shimmer 2s ease-out infinite;
  content: "";
}

.shim-blue {
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 155, 255, 0.7);
}
.shim-blue::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(233, 233, 233, 1) 0,
    rgba(233, 233, 233, 0.9) 50%,
    rgba(233, 233, 233, 0.8) 100%
  );
  animation: shimmer 2.5s ease-out infinite;
  content: "";
}

.shim-red {
  position: relative;
  overflow: hidden;
  background-color: rgba(255, 0, 0, 0.7);
}
.shim-red::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(233, 233, 233, 1) 0,
    rgba(233, 233, 233, 0.9) 50%,
    rgba(233, 233, 233, 0.8) 100%
  );
  animation: shimmer 3s ease-out infinite;
  content: "";
}

@keyframes shimmer {
  100% {
    transform: translateX(0%);
    opacity: 0;
  }
}
