.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.edit-widget {
  .react-grid-item {
    transition: all 200ms ease;
    transition-property: left, top;
    // &:hover {
    //   transform: rotate(-5deg) !important;
    //   transform-origin: center !important;
    // }
    // &:active {
    //   transform: none;
    // }
    &:hover {
      padding: 15px;
      -webkit-box-shadow: -2px -1px 15px 7px rgba(0, 0, 0, 0.5);
      -moz-box-shadow: -3px -2px 30px 14px rgba(0, 0, 0, 0.425);
      box-shadow: -1px -1px 25px 5px rgba(0, 0, 0, 0.15);
      cursor: move; /* fallback if grab cursor is unsupported */
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;
      border-radius: 15px;
    }
  }
  .react-grid-item.react-draggable-dragging {
    // background: white;
    // transform: rotate(-20deg);
    // transform: rotate(-5deg) !important;
    border-radius: 15px;
    transition: none;
    z-index: 3;
    will-change: transform;

    padding: 15px;
    -webkit-box-shadow: -2px -1px 15px 7px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: -3px -2px 30px 14px rgba(0, 0, 0, 0.425);
    box-shadow: -1px -1px 25px 5px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }

  .react-grid-item.react-grid-placeholder {
    border-radius: 20px;
    // background-color: rgba(166, 169, 200, 0.18);
    background-image: radial-gradient(black 1px, transparent 0);
    background-size: 40px 40px;
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }

  .react-grid-item > .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    cursor: se-resize;
  }

  .react-grid-item > .react-resizable-handle::after {
    content: "";
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 10px;
    height: 10px;
    border-right: 5px solid rgba(69, 101, 246, 0.6);
    border-bottom: 5px solid rgba(69, 101, 246, 0.6);
  }

  .react-grid-item:not(.react-grid-placeholder) {
    // background: grey;
  }
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  background: white;
  z-index: 1;
  will-change: width, height;
}

.window-header {
  background-color: lightblue;
  display: flex;
  justify-content: space-between;
  height: 25px;
}

.logo-container {
  overflow: hidden;
}

.app-name {
  color: black;
  font-size: 14px;
  margin-bottom: 5px;
  padding-left: 8px;
}

.actions-container {
  display: flex;
  padding-top: 3px;
}

.icon {
  color: black;
  font-size: 20px;
  padding-top: 5px;
}

.window-button-style {
  background-color: transparent;
  border: none;
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
  transition: background-color 150ms ease;
}

.window-controls-icon {
  pointer-events: none;
  user-select: none;
  width: 20px;
  height: 20px;
  transition: fill 150ms ease;
}

.settings-window:hover {
  background-color: orange;
}

.close-window:hover {
  background-color: #e6004c;
}

.settings-window:focus {
  outline: none;
}

.close-window:focus {
  outline: none;
}

.addButton:hover {
  letter-spacing: 0.8em;
  background-color: lightskyblue;
  cursor: pointer;
}

.addButton {
  height: 4em;
  width: 10%;
  min-width: 120px;
  padding: 1.5em auto;
  margin: 1em auto;
  background-color: lightblue;
  border: none;
  border-radius: 3px;
  text-transform: uppercase;
  letter-spacing: 0.5em;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.windowHeader {
  background-color: lightblue;
  display: flex;
  justify-content: space-between;
  height: 25;
}
.logoContainer {
  overflow: hidden;
}
.appName {
  color: black;
  font-size: 14;
  margin-bottom: 5;
  padding-left: 8;
}
.actionsContainer {
  display: flex;
  padding-top: 3;
}
.settingsWindow {
  &:hover {
    background-color: orange;
  }
  &:focus {
    outline: none;
  }
  background-color: transparent;
  border: none;
  width: 20;
  height: 20;
  padding: 0;
  margin: 0;
  transition: background-color 150ms ease;
}

.windowControlsIcon {
  pointer-events: none;
  user-select: none;
  width: 40;
  height: 40;
  transition: fill 150ms ease;
}
.addButton {
  height: 4em;
  width: 10%;
  min-width: 120;
  padding: 1.5em auto;
  margin: 1em auto;
  text-transform: uppercase;
  letter-spacing: 0.5em;
}
.gridItem {
  cursor: grab;
}
