.forgetPassword {
  // .formLogin {
  //   height: 250px !important;
  // }
  padding: 5px;
  .logo-bloc-katomi {
    img {
      width: 76px;
      height: 76px;
    }
    .logo_text {
      font-size: 34.5px;
      font-weight: 900;
      color: #fff;
      font-family: Roboto;
      letter-spacing: normal;
    }
  }
  @media screen and (max-width: 420px) {
    input {
      width: 320px;
    }
    .form-forget-password {
      padding: 15px 2px !important;
      margin: auto 5px;
    }
  }
}
