.addDocs {
  background: #ffffff;
  box-shadow: -11px 0px 40px -20px rgba(140, 151, 175, 0.4);
  border-radius: 20px;
  min-width: 500px;
  min-height: 200px;
  @media screen and (max-width: 800px) {
    min-width: auto;
  }
  .thumbsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16;
  }
  .thumb {
    display: inline-flex;
    border-radius: 2;
    border: 1px solid #eaeaea;
    margin-bottom: 8;
    margin-right: 8;
    width: 100;
    height: 100;
    padding: 4;
    box-sizing: border-box;
  }
  .thumbInner {
    display: flex;
    min-width: 0;
    overflow: hidden;
  }
  img {
    display: block;
    width: 100px;
    max-height: 100px;
  }
  .dropzone {
    margin: 40px 20px 20px 20px;
    border-radius: 20px;
    border: 2px aquamarine dashed;
    height: 90px;
  }
}

.modalFilter {
  border-radius: 20px;
  min-width: 650px;
}
