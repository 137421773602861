// @media screen and (max-width: 1440px) {

.profile-doctor {
  width: 100%;
  .warn,
  .warn::before,
  .warn::after {
    position: relative;
    padding: 0;
    margin: 0;
  }

  .warn {
    font-size: 20px;
    color: transparent;
  }
  .box-expertise {
    min-height: 35px;
    background: rgba(166, 169, 200, 0.35) !important;
    border-radius: 9px;
    p {
      font-size: 14px;
      line-height: 16px;
      color: #333;
    }
  }
  .warn.warning {
    display: inline-block;

    top: 0.225em;

    width: 1.15em;
    height: 1.15em;

    overflow: hidden;
    border: none;
    background-color: transparent;
    border-radius: 0.625em;
  }

  .warn.warning::before {
    content: "";
    display: block;
    top: -0.08em;
    left: 0em;
    position: absolute;
    border: transparent 0.6em solid;
    border-bottom-color: #fd3;
    border-bottom-width: 1em;
    border-top-width: 0;
    box-shadow: #999 0 1px 1px;
  }

  .warn.warning::after {
    display: block;
    position: absolute;
    top: 0.15em;
    left: 0;
    width: 100%;
    padding: 0 1px;
    text-align: center;
    font-family: "Garamond";
    content: "!";
    font-size: 0.65em;
    font-weight: bold;
    color: #333;
  }
  .btnAdd {
    margin-left: 10px;
  }
  .modifier {
    font-weight: 500;
    font-size: 16px;
    color: #827f9b;
    margin-right: 21px;
    cursor: pointer;
  }
  .PresentationBox {
    // width: 542px;
    min-height: 217px;
    height: max-content;
    border-radius: 20px;
    background-color: #fbfbfd;
    .headerContainer {
      //   // width: 542px;
      height: 67px;
      border-bottom: 1px solid rgba(166, 169, 200, 0.3);
      .svgClass {
        width: 26px;
        height: 26px;
        margin-left: 20px;
      }
      .header {
        width: 167px;
        margin-left: 6px;
        font-size: 20px;
        line-height: 23px;
        text-transform: uppercase;
        color: #827f9b;
        letter-spacing: -0.5px;
      }
    }
    .textClassContainer {
      background-color: white;
      .textClass {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 15.5px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .psc {
    margin-bottom: 15px;
    min-height: 150px;
    height: max-content;
    border-radius: 20px;
    background-color: #fbfbfd;
    .headerContainer {
      //   // width: 542px;
      margin-left: 10px;
      height: 67px;
      border-bottom: 1px solid rgba(166, 169, 200, 0.3);
    }
    .textClassContainer {
      background-color: white;
      .textClass {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 15.5px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .info {
    // margin-top: 62.6px;
    .doctorNames {
      // margin-top: 21.4px;
      .doctorTitle {
        font-weight: bold;
        font-size: 26px;
        line-height: 30px;
        @media screen and (max-width: 768px) {
          font-size: 22px;
        }
      }
      .specailities {
        padding-top: 16px;
      }
    }
    .doctorContainer {
      width: 163.2px;
      height: 148.8px;
      .imageDoctor {
        width: 150px;
        max-height: 150px;
      }
      @media screen and (max-width: 500px) {
        width: 100px;
        height: 90px;
        .imageDoctor {
          width: 95px;
          height: 90px;
        }
      }
    }
  }
  .location {
    // width: 542px;
    height: 374px;
    border-radius: 20px;
    background-color: #fbfbfd;
    .headerContainer {
      // width: 542px;
      height: 67px;
      border-bottom: 1px solid rgba(166, 169, 200, 0.3);
      .svgClass {
        width: 26px;
        height: 26px;
        margin-left: 20px;
      }
      .header {
        margin-left: 6px;
        font-size: 20px;
        line-height: 23px;
        text-transform: uppercase;
        color: #827f9b;
        letter-spacing: -0.5px;
      }
    }
    .hide-col-mobile {
      @media screen and (max-width: 998px) {
        display: none;
      }
    }

    .locationContainer {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 17px;
    }
    h1 {
      height: 21px;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
    }
    div:nth-child(even) {
      //margin-bottom: 16px;
    }
    .address {
      margin-left: 12.5px;
    }
    .infoDetails {
      margin-top: 16.5px;
    }
  }
  .expertises {
    // width: 542px;
    min-height: 142px;
    margin-top: 15px;
    border-radius: 20px;
    background-color: #fbfbfd;
    .headerContainer {
      // width: 542px;
      height: 67px;
      border-bottom: 1px solid rgba(166, 169, 200, 0.3);
      .svgClass {
        width: 26px;
        height: 26px;
        margin-left: 20px;
      }
      .header {
        margin-left: 6px;
        font-size: 20px;
        line-height: 23px;
        text-transform: uppercase;
        color: #827f9b;
        letter-spacing: -0.5px;
      }
    }
    .specailities {
      margin-top: 20.5px;
      margin-left: 20px;
      // div:nth-child(odd) {
      //   margin-right: 10px;
      // }
    }
  }
  .signature {
    height: auto;
    // width: 542px;
    border-radius: 20px;
    background-color: #fbfbfd;
    margin-top: 20px;
    .headerContainer {
      // width: 542px;
      height: 67px;
      border-bottom: 1px solid rgba(166, 169, 200, 0.3);
      .svgClass {
        width: 26px;
        height: 26px;
        margin-left: 20px;
      }
      .header {
        margin-left: 6px;
        font-size: 20px;
        line-height: 23px;
        text-transform: uppercase;
        color: #827f9b;
        letter-spacing: -0.5px;
      }
    }
  }
  .langues {
    height: 142px;
    // width: 542px;
    border-radius: 20px;
    background-color: #fbfbfd;
    .headerContainer {
      // width: 542px;
      height: 67px;
      border-bottom: 1px solid rgba(166, 169, 200, 0.3);
      .svgClass {
        width: 26px;
        height: 26px;
        margin-left: 20px;
      }
      .header {
        margin-left: 6px;
        font-size: 20px;
        line-height: 23px;
        text-transform: uppercase;
        color: #827f9b;
        letter-spacing: -0.5px;
      }
    }
    .consultationsDetails {
      font-size: 16px;
      font-weight: bold;
      margin: 16px 13px 0px 13px;
      padding-bottom: 19px;
      border-bottom: 1px solid rgba(166, 169, 200, 0.3);
      ul {
        padding-left: 24px;
        li {
          list-style-type: disc;
          div {
            line-height: 24px;
          }
        }
      }
    }
    .paiements {
      padding-top: 15px;
      padding-left: 20px;
      background-color: white;
      .boxMethod {
        height: 35px;
        background: rgba(166, 169, 200, 0.35) !important;
        border-radius: 9px;
        color: #a6a9c8;
        padding-left: 11px;
        padding-right: 8px;
        &:nth-child(even) {
          margin-right: 10px;
          margin-left: 10px;
        }
        img {
          width: 23px;
          height: 23px;
          margin-right: 5px;
        }
        p {
          font-size: 14px;
          font-weight: 400;
        }
      }
      .btnAdd {
        margin-left: 10px;
      }
    }
  }
  .blocCursusTarif {
    margin-bottom: 15px;
    margin-top: 15px;
    .tarifs {
      // width: 542px;
      height: min-content;
      border-radius: 20px;
      background-color: #fbfbfd;
      .headerContainer {
        // width: 542px;
        height: 67px;
        border-bottom: 1px solid rgba(166, 169, 200, 0.3);
        padding-left: 20px;
        .svgClass {
          width: 26px;
          height: 26px;
          margin-left: 20px;
        }
        .header {
          margin-left: 6px;
          font-size: 20px;
          line-height: 23px;
          text-transform: uppercase;
          color: #827f9b;
          letter-spacing: -0.5px;
        }
      }
      .consultationsDetails {
        font-size: 16px;
        font-weight: bold;
        padding: 16px 13px 0px 13px;
        padding-bottom: 19px;
        border-bottom: 1px solid rgba(166, 169, 200, 0.3);
        background-color: white;
        ul {
          padding-left: 24px;
          li {
            list-style-type: disc;
            div {
              line-height: 24px;
            }
          }
        }
      }
      .paiements {
        padding-top: 15px;
        padding-left: 20px;
        padding-bottom: 15px;
        background-color: white;
        .boxMethod {
          height: 35px;
          background: rgba(166, 169, 200, 0.35) !important;
          border-radius: 9px;
          color: #a6a9c8;
          padding-left: 11px;
          padding-right: 8px;
          &:nth-child(even) {
            margin-right: 10px;
            margin-left: 10px;
          }
          img {
            width: 23px;
            height: 23px;
            margin-right: 5px;
          }
          p {
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }
    .cursus {
      // width: 542px;
      min-height: 317px;
      height: max-content;
      border-radius: 20px;
      border-radius: 20px;
      background-color: #fbfbfd;
      .headerContainer {
        // width: 542px;
        height: 67px;
        border-bottom: 1px solid rgba(166, 169, 200, 0.3);
        .svgClass {
          width: 23px;
          height: 23px;
          margin-left: 30px;
        }
        .header {
          margin-left: 6px;
          font-size: 20px;
          line-height: 23px;
          text-transform: uppercase;
          color: #827f9b;
          letter-spacing: -0.5px;
        }
      }
      .cursusContainer {
        padding-left: 24px;
        padding-top: 27.5px;
        background-color: white;
        .images {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .details {
          .typeDiplome {
            font-size: 18px;
            font-weight: bold;
            text-align: left;
            color: #333;
          }
          .anneeDiplome {
            flex-grow: 0;
            font-family: Ubuntu;
            font-size: 14px;
            text-align: left;
            color: #333;
          }
          .university {
            font-size: 14px;
            text-align: left;
            color: #a6a9c8;
          }
        }
      }
    }
  }
  .sites {
    // width: 542px;
    height: 280px;
    border-radius: 20px;
    border-radius: 20px;
    background-color: #fbfbfd;
    margin-top: 15px;
    .headerContainer {
      // width: 542px;
      height: 67px;
      border-bottom: 1px solid rgba(166, 169, 200, 0.3);
      .svgClass {
        width: 26px;
        height: 26px;
        margin-left: 20px;
      }
      .header {
        margin-left: 6px;
        font-size: 20px;
        line-height: 23px;
        text-transform: uppercase;
        color: #827f9b;
        letter-spacing: -0.5px;
      }
    }
    .containerSites {
      padding-right: 23px;
      padding-left: 23px;
      background-color: white;
      .boxSite {
        border-bottom: 1px solid rgba(166, 169, 200, 0.3);
        padding-bottom: 14px;
        padding-top: 14px;
        &:last-child {
          border-bottom: none;
        }
        img {
          // width: 16px;
          // height: 24px;
          margin-right: 21px;
        }
        p {
          font-size: 16px;
          font-weight: 700;
          margin-left: 21px;
        }
        .svgContainer {
          min-width: 23px;
          min-height: 23px;
        }
      }
    }
  }
  .addbtnProfile {
    width: 103px;
    height: 35px;
    border: 1px solid #4565f6;
    box-sizing: border-box;
    border-radius: 9px;
  }
  //}
}

.alert-strip {
  background-color: #fefbe6;
  border: 1px solid #f9e48f;
  padding: 5px 10px;
}

.boxSpecialiteProfile {
  width: 100%;
  min-height: 35px;
  background: rgba(166, 169, 200, 0.35) !important;
  border-radius: 9px;
  p {
    font-size: 14px;
    line-height: 16px;
    color: #333;
  }
  @media screen and (max-width: 500px) {
    width: 90%;
  }
}
.card-presentation {
  width: 500px;
  @media screen and (max-width: 550px) {
    width: 350px;
  }
}

.widthFirstStepper {
  width: 50vw;
  @media screen and (max-width: 1250px) {
    width: 60vw;
  }
  @media screen and (max-width: 900px) {
    width: 70vw;
  }
  @media screen and (max-width: 600px) {
    width: 80vw;
  }
}
