.my-folder-patient {
  .card-patient-details .avatar {
    // width: 102px;
    // height: 102px;

    svg {
      width: 100px;
      height: 100px;
    }
  }
  .boxAntecedent {
    min-width: 358px;
    min-height: 164px;
  }
}

.traitement-detail-modal {
  font-size: 14px;
  line-height: 1.29;
}
.patient-profile-responsive {
  @media screen and (max-width: 1024px) {
    border: 0.5px dashed rgba(128, 128, 128, 0.5);
    padding: 15px;
    border-radius: 20px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
      0 1px 2px -1px rgba(0, 0, 0, 0.1);
  }
}

.history-scroll-overflow {
  max-height: 600px;
  overflow: auto;
}
